import React from "react"
import { Link } from "gatsby"

import Slider from "react-slick";
import Lottie from 'react-lottie-player';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './react-slick.css';

import brainHeadphones from '../lottie/5dc9ac99fd85fd17cde8aa66_brain-headphones-oval.json';
import brainMountain from '../lottie/5ddf0b46cf77c626d901bf33_brain-mountain.json';
import brainMeditating from '../lottie/5dcc7ec254737639f8dff46d_data.json';


const settings = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
};


const brainStyle = { width: '100%', maxWidth: 300, margin: '-10% auto -5% auto' };

const Carousel = () => (
  <div style={{ paddingTop: 30, paddingBottom: 30, backgroundColor: '#31c0a8' }}>
    <Slider {...settings}>
      <div className="">
        <div className="row row-split-content">
          <div className="content-width-medium">
            <h1 className="text-white hero">Over 1 Million Lives Improved</h1>
            <p className="text-white hero"><strong>Brought to you by Effective Learning Systems. Click the button to learn more about the WiseGuide app!</strong></p>
            <Link to="/pricing/" className="button bg-gray-3 w-inline-block">
              <div>Get Started</div>
            </Link>
          </div>
          <div className="content-width-large">
            <Lottie loop play animationData={brainHeadphones} style={brainStyle} />
          </div>
        </div>
      </div>

      <div className="">
        <div className="row row-split-content">
          <div className="content-width-medium">
            <h1 className="text-white">Feel the Success </h1>
            <p className="text-white hero"><strong>Success starts in your mind. The WiseGuide App can help you change your thought habits and achieve your goals.</strong></p>
            <Link to="/programs/" className="button bg-gray-3 w-inline-block">
              <div>View programs</div>
            </Link>
          </div>
          <div className="content-width-large">
            <Lottie loop play animationData={brainMountain} style={brainStyle} />
          </div>
        </div>
      </div>

      <div className="">
        <div className="row row-split-content">
          <div className="content-width-medium">
            <h1 className="text-white hero">Goal Oriented Meditation </h1>
            <p className="text-white hero">World leaders, athletes, and top performers meditate to achieve their goals. The WiseGuide App will guide you to make your meditation most productive. Just listen</p>
            <Link to="/how-it-works/" target="_blank" className="button bg-gray-3 w-inline-block">
              <div>How it works</div>
            </Link>
          </div>
          <div className="content-width-large">
            <Lottie loop play animationData={brainMeditating} style={brainStyle} />
          </div>
        </div>
      </div>
    </Slider>
  </div>
);

export default Carousel;