import React, { memo, useState } from 'react';
import { Link } from "gatsby"
import { AnimatePresence, motion } from 'framer-motion';
import { FaShoppingBasket } from 'react-icons/fa';


export default memo(({ showCart }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <div className="navbar-sticky bg-white">
      <div className="navigation-container">
        <div className="container">
          <div data-collapse="medium" data-animation="default" data-duration={400} className="navbar w-nav">
            <Link to="/" aria-current="page" className="w-nav-brand w--current"><img src="/images/5dc99e77aa4ac35b8886233e_The%20Wise%20Guide%20App%20Logo.png" width={246} alt="" /></Link>
            <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
              <div className="nav-links nav-links-1">
                <Link partiallyActive activeClassName="w--current" to="/how-it-works/" className="nav-link w-nav-link">How it Works</Link>
                <Link partiallyActive activeClassName="w--current" to="/pricing/" className="nav-link w-nav-link">Pricing</Link>
                <Link partiallyActive activeClassName="w--current" to="/programs/" className="nav-link w-nav-link">Programs</Link>
                <Link partiallyActive activeClassName="w--current" to="/case-studies/" className="nav-link w-nav-link">Testimonials</Link>
                <Link partiallyActive activeClassName="w--current" to="/blog/" className="nav-link w-nav-link">Blog</Link>
                <a href="https://checkout.thewiseguideapp.com/my-account/" className="nav-link w-nav-link">My Account</a>

                <div data-delay={0} data-hover={1} className="nav-dropdown w-dropdown">
                  <div className="nav-link w-dropdown-toggle">
                    <div className="row no-wrap">
                      <div>Company</div><img src="/fonts/5dc99dd0c36766290e593700_icon-caret-down.svg" alt="" className="dropdown-icon" />
                    </div>
                  </div>
  
                  <nav className="dropdown-list w-dropdown-list">
                    <div className="dropdown-menu">
                      <Link to="/blog/" className="dropdown-link w-dropdown-link">Blog</Link>
                    </div>
                  </nav>
                </div>
              </div>
            </nav>

            <div className="nav-menu-button-wrapper">
              <div className="nav-functions">
                <div className="modal-container">
                  <div className="modal">
                    <div className="modal-contents">
                      <form action="/search" className="modal-search-form w-form"><img src="/fonts/5dc99dd0c36766d86b593714_icon-search.svg" alt="" className="form-input-icon" /><input type="search" className="form-input form-input-naked w-input" maxLength={256} name="query" placeholder="Type your search" id="search-2" required /><input type="submit" defaultValue="Search" className="button form-search-button w-button" /></form>
                    </div>
                  </div>
                  <div data-w-id="276b6801-f379-97a7-f954-0c7c8dcfdbba" className="modal-screen" />
                </div>
                <Link to="/pricing/" ms-hide-element="true" className="button bg-primary-2 w-button">Get started</Link>
              </div>

              {showCart && <a href="https://checkout.thewiseguideapp.com/cart/"><FaShoppingBasket size={26} style={{ marginLeft: 10, marginRight: 5, marginTop: 5, color: 'black' }} /></a>}

              <div role="button" tabIndex="0" className={`menu-button w-nav-button ${navExpanded ? 'w--open' : ''}`} onClick={() => setNavExpanded(v => !v)}>{navExpanded ? <img src="/fonts/5dc99dd0c367664754593713_icon-x.svg" alt="" className="menu-icon" /> : <img src="/fonts/5dc99dd0c367669ba7593711_icon-menu.svg" alt="" className="menu-icon" />}</div>
            </div>

            <AnimatePresence>
              {navExpanded && (
                <motion.div transition={{ ease: 'easeOut' }} initial={{ y: '-100vh', opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: '-100vh', opacity: 0 }} className="w-nav-overlay" data-wf-ignore style={{height: '4822px'}}>
                  <nav role="navigation" className="nav-menu-wrapper w-nav-menu" data-nav-menu-open style={{transform: 'translateY(0px)', transition: 'transform 400ms ease 0s'}}>
                      <div className="nav-links nav-links-1">
                        <Link partiallyActive activeClassName="w--current" className="nav-link w-nav-link w--nav-link-open" to="/how-it-works/">How it Works</Link>
                        <Link partiallyActive activeClassName="w--current" className="nav-link w-nav-link w--nav-link-open" to="/pricing/">Pricing</Link>
                        <Link partiallyActive activeClassName="w--current" className="nav-link w-nav-link w--nav-link-open" to="/programs/">Programs</Link>
                        <Link partiallyActive activeClassName="w--current" className="nav-link w-nav-link w--nav-link-open" to="/case-studies/">Testimonials</Link>
                        <Link partiallyActive activeClassName="w--current" className="nav-link w-nav-link w--nav-link-open" to="/blog/">Blog</Link>
                        <a href="https://checkout.thewiseguideapp.com/my-account/" className="nav-link w-nav-link w--nav-link-open">My Account</a>
                      </div>
                  </nav>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
});
