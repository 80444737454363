
export function getPostImagePath(post) {
  return post.frontmatter.featuredImage && post.frontmatter.featuredImage.childImageSharp.fluid;
}

// todo
export function getCategoryPath() {
  return '/blog/';
}

export function getAuthorPath() {
  return '/bob-griswold/'; // TODO
}

export function getTagPath() {
  return '/blog/'; // todo
}