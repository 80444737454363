import React, { useRef, useEffect, useState } from "react"
import moment from 'moment';

import './flashsale.css'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
const Digit = ({ val }) => {
  const ref = useRef();

  useEffect(() => {
    ref.current.style.animationName = '';
    window.requestAnimationFrame(() => {
      ref.current.style.animationName = 'pulse';
    });
  
  }, [val]);

  return (
    <div className="flash-sale-digit" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '1em', height: '1.4em', textAlign: 'center', background: 'black', borderRadius: '.15em', padding: '.2em auto', color: 'white', position: 'relative', marginRight: '.2em' }}>
      <div style={{ animationDuration: '.3s', animationName: 'pulse' }} ref={ref}>{val}</div>
      <div style={{ position: 'absolute', top: 0, left: 0, borderTopLeftRadius: '.15em', borderTopRightRadius: '.15em', width: '100%', height: '53%', background: 'rgba(0,0,0,0.4)' }} />
      <div style={{ position: 'absolute', bottom: 0, left: 0, borderBottomLeftRadius: '.15em', borderBottomRightRadius: '.15em', width: '100%', height: '47%', background: 'rgba(255,255,255,0.1)' }} />
    </div>
  );
}

const Group = ({ children, name }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '.7em', marginLeft: '.7em' }}>
  <div style={{ display: 'flex' }}>
    {children}
  </div>
  <div style={{ color: 'white', textTransform: 'uppercase', marginTop: '.4em', fontSize: '1.4em' }}>{name}</div>
</div>
);

const FlashSale = () => {
  const [hasMounted, setHasMounted] = useState(false); // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  const [flashSaleDates, setFlashSaleDates] = useState([
    '2022-11-25 00:00:00',
  ]);
  
  function getRelTime() {
    for (const flashSaleDate of flashSaleDates) {
      const secondsIntoFlashSale = Math.floor(moment().diff(moment(flashSaleDate)) / 1000);
      if (secondsIntoFlashSale >= 0 && secondsIntoFlashSale < 24 * 60 * 60) return secondsIntoFlashSale;
    }
  
    return null;
  }
  useEffect(() => {
    setHasMounted(true);
  }, []);

  
  useEffect(() => {
    // for testing
    window.setFlashSaleDates = (newDates) => {
      setFlashSaleDates(newDates);
    }
  }, []);

  const [secondsIntoFlashSale, setTime] = useState(getRelTime());

  useInterval(() => {
    setTime(getRelTime());
  }, 1000);

  if (!hasMounted) return null;

  if (secondsIntoFlashSale == null) return null;

  const secondsRemaining = 24 * 60 * 60 - secondsIntoFlashSale;

  const components = `${String(secondsRemaining % 60).padStart(2, 0)}${String(Math.floor(secondsRemaining / 60) % 60).padStart(2, 0)}${String(Math.floor(secondsRemaining / (60 * 60))).padStart(2, 0)}`;

  return (
    <div style={{ background: 'linear-gradient(180deg, rgba(118,74,241,1) 0%, rgba(8,129,235,1) 100%)', color: 'white', padding: '3em 2em' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3em' }}>
          <Group name="hours">
            <Digit val={components[4]} />
            <Digit val={components[5]} />
          </Group>
          <Group name="minutes">
            <Digit val={components[2]} />
            <Digit val={components[3]} />
          </Group>
          <Group name="seconds">
          <Digit val={components[0]} />
          <Digit val={components[1]} />
          </Group>
        </div>
  
        <a
          style={{ color: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
          href="https://checkout.thewiseguideapp.com/product/flash-sale-special-sleep-bundle/"
        >
          <span style={{ fontSize: '3em', fontWeight: 200 }}>
            FLASH SALE
          </span><br />

          SPECIAL SLEEP BUNDLE
        </a>
      </div>
    </div>
  );
}

export default FlashSale
