import React from 'react';
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"

import { getPostImagePath } from '../util';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 4) {
        edges {
          node {
            frontmatter {
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const edges = data.allMarkdownRemark.edges;

  return (
    <div className="footer bg-primary-3">
      <div className="container">
        <div className="footer-row">
          <div className="footer-left"><Link to="/" aria-current="page" className="footer-logo w-inline-block w--current"><img src="/images/5dc99e77aa4ac35b8886233e_The%20Wise%20Guide%20App%20Logo.png" width={276} alt="" /></Link>
            <p className="text-large footer-text">Over one million lives improved<br /></p>
            <ul className="social-icons-list w-list-unstyled">
              <li className="list-item"><a href="https://twitter.com/wiseguideapp" target="_blank" rel="noopener noreferrer" className="button-circle button-small bg-white w-inline-block"><img src="/fonts/5dc99dd0c3676649b8593755_twitter.svg" alt="" className="social-icon" /></a></li>
              <li className="list-item"><a href="https://www.instagram.com/thewiseguideapp/" target="_blank" rel="noopener noreferrer" className="button-circle button-small bg-white w-inline-block"><img src="/fonts/5dc99dd0c3676634c8593754_instagram.svg" alt="" className="social-icon" /></a></li>
              <li className="list-item"><a href="https://www.facebook.com/wiseguideapp" target="_blank" rel="noopener noreferrer" className="button-circle button-small bg-white w-inline-block"><img src="/fonts/5dc99dd0c367666c5959374e_facebook.svg" alt="" className="social-icon" /></a></li>
              {/* <li className="list-item"><a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer" className="button-circle button-small bg-white w-inline-block"><img src="/fonts/5dc99dd0c36766da1259375d_linkedin.svg" alt="" className="social-icon" /></a></li> */}
              {/* <li className="list-item"><a href="https://dribbble.com/" target="_blank" rel="noopener noreferrer" className="button-circle button-small bg-white w-inline-block"><img src="/fonts/5dc99dd0c367668cf459375c_dribbble.svg" alt="" className="social-icon" /></a></li> */}
            </ul>
          </div>
          <div className="footer-columns-wrapper footer-4-columns-wrapper">
            <div className="footer-menu-column">
              <ul className="link-list w-list-unstyled" />
            </div>
            <div className="footer-menu-column">
              <h6 className="widget-heading">Company</h6>
              <ul className="link-list w-list-unstyled">
                <li><Link to="/how-it-works/" className="hover-link">How it works</Link></li>
                <li><Link to="/programs/" className="hover-link">Programs</Link></li>
                <li><Link to="/pricing/" className="hover-link">Pricing</Link></li>
                <li><Link to="/blog/" className="hover-link">Blog</Link></li>
                <li><Link to="/privacypolicy/" className="hover-link">Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="widget footer-menu-column">
              <h6 className="widget-heading">Articles</h6>
              <div className="w-dyn-list">
                <div className="grid-rows grid-rows-compact w-dyn-items">
                  {edges.map(({ node }) => (
                    <div key={node.fields.slug} className="w-dyn-item"><Link to={node.fields.slug} className="blog-post-small text-white hover-link w-inline-block">{getPostImagePath(node) && <Img fluid={getPostImagePath(node)} className="sidebar-blog-post-image" />}
                      <div className="text-block-6">{node.frontmatter.title}</div>
                    </Link></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-lower-row">
          <div className="text-block-7">© 2020<a href="https://www.effectivelearning.com"> Effective Learning Systems, LLC</a> - PO Box 366666 Bonita Springs, FL 34136 (612) 513-0760   <a href="mailto:info@thewiseguideapp.com">info@thewiseguideapp.com</a></div>
        </div>
      </div>
    </div>
  );
}
