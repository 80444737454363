import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from '../components/Carousel';
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';

import FlashSale from './FlashSale';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div>
      <FlashSale />
      <Carousel />

      <ImproveYourLife />

      <div className="section how-it-works">
        <div className="section-title">
          <h2 className="h2 center text-white">How it works</h2>
          <div className="text-block">For more info click <Link to="/how-it-works/" className="link-2">here</Link></div>
        </div>
        <div className="container"><img src="/images/5dcc82265473762506e02644_brain-desc.png" srcSet="/images/5dcc82265473762506e02644_brain-desc-p-500.png 500w,/images/5dcc82265473762506e02644_brain-desc.png 914w" sizes="(max-width: 479px) 95vw, (max-width: 767px) 94vw, (max-width: 962px) 95vw, 914px" alt="" className="image" /></div>
      </div>
      <div className="section">
        <div className="container">
          <div className="section bg-gray-4">
            <div className="container">
              <div className="w-layout-grid grid-thirds gapless-columns">
                <div className="centered-grid-item">
                  <div className="restricted-grid-item">
                    <h4 className="h1 text-primary-2">1m+</h4>
                    <div>Lives changed by eliminating negative thought habits and establishing new, productive healthy thought habits!</div>
                  </div>
                </div>
                <div className="centered-grid-item">
                  <div className="restricted-grid-item border-sides-white-thick">
                    <h4 className="h1 text-primary-2">120+</h4>
                    <div>Programs that are tested and proven to work </div>
                  </div>
                </div>
                <div className="centered-grid-item">
                  <div className="restricted-grid-item">
                    <h4 className="h1 text-primary-2">45+</h4>
                    <div>Years of experience in improving lives</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="background bg-warning" />
        <div className="container">
          <div className="section-title">
            <h6 className="subheading text-primary-1">Our Programs</h6>
            <h3 className="h1">Prescriptive programs to improve any aspect of your life</h3>
          </div>
          <div className="w-layout-grid grid-thirds gapless-columns gapless-rows">
            <div className="padded-grid-item border-bottom-white-thick">
              <div className="centered-grid-item"><img src="/fonts/5dcc89a4449e5925d633cdba_no-smoking.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Quit Smoking</h6>
                <div>You are stronger than your addiction</div>
              </div>
            </div>
            <div className="padded-grid-item border-bottom-white-thick border-sides-white-thick">
              <div className="centered-grid-item"><img src="/fonts/5dcc897b5473763c45e06869_weigh-scale.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Weight Control</h6>
                <div>Win the mental game of weight-control<strong><br /></strong></div>
              </div>
            </div>
            <div className="padded-grid-item border-bottom-white-thick">
              <div className="centered-grid-item"><img src="/fonts/5dcc894438d58ce610a7adbb_ghost.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Conquer Fears</h6>
                <div>Don’t let fears hold you back<br /></div>
              </div>
            </div>
            <div className="padded-grid-item">
              <div className="centered-grid-item"><img src="/fonts/5dcc89c038d58cde14a7b066_stressed.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Manage Stress</h6>
                <div>Relieve stress and feel consistently relaxed and happy</div>
              </div>
            </div>
            <div className="padded-grid-item border-sides-white-thick">
              <div className="centered-grid-item"><img src="/fonts/5dcc89da5473760bcee06ac0_moon.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Sleep Better</h6>
                <div>Enjoy restful revitalizing sleep every night</div>
              </div>
            </div>
            <div className="padded-grid-item">
              <div className="centered-grid-item"><img src="/fonts/5dcc8a0338d58cd164a7b149_piggy-bank%20%281%29.svg" alt="" className="icon-large" />
                <h6 className="h6-small">Attract Money</h6>
                <div>You can win the money game</div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: 'center', fontSize: 40, marginTop: 30 }}>...and more!</div>
        </div>
      </div>
      <div className="section bg-gray-4">
        <div className="container">
          <div className="row row-split-content">
            <div className="content-width-small">
              <h6 className="subheading text-primary-1">Thousands of glowing reviews </h6>
              <h3 className="h1">Here are a few reviews from people like you</h3>
              <div>Tens of thousands of people have reviewed our programs over the years. We highlighted some of our favorite success stories. </div>
              <Link to="/case-studies/" className="button bg-white margin-top w-inline-block">
                <div>More testimonials</div>
              </Link>
            </div>
            <div className="content-width-large">
              <div className="w-layout-grid grid-halves">
                <div className="w-layout-grid grid-rows">
                  <div data-w-id="4783cacc-8e65-fdc8-d1e9-ff479689dae1" className="card no-border">
                    <div className="card-head no-border">
                      <div className="row row-align-center"><img src="/images/5dc99dd0c36766b237593780_avatar-male-1.jpg" alt="" className="avatar avatar-small" />
                        <div className="text-space-left">
                          <div className="text-small"><strong>Very Relaxing</strong></div>
                          <div className="text-small text-muted">@angsam95</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="text-small">I listen to this almost every evening before I go to bed and it does relax me. I feel the relaxation and stress reduction as I am listening to it</div>
                    </div>
                  </div>
                  <div data-w-id="4783cacc-8e65-fdc8-d1e9-ff479689daee" className="card no-border">
                    <div className="card-head no-border">
                      <div className="row row-align-center"><img src="/images/5dc99dd0c367661ded59377d_avatar-female-3.jpg" alt="" className="avatar avatar-small" />
                        <div className="text-space-left">
                          <div className="text-small"><strong>Perfect App</strong></div>
                          <div className="text-small text-muted">@emilyb</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="text-small">I would give this ten stars if I could! This app is fantastic, and truly relaxes your whole body. It also helps me to get deep sleep. This is a app I will be listening to all the time. Finally a app that is perfect!</div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-grid grid-rows">
                  <div data-w-id="4783cacc-8e65-fdc8-d1e9-ff479689dafc" className="card no-border">
                    <div className="card-head no-border">
                      <div className="row row-align-center"><img src="/images/5dc99dd0c3676647a259377e_avatar-male-2.jpg" alt="" className="avatar avatar-small" />
                        <div className="text-space-left">
                          <div className="text-small"><strong>It works!</strong></div>
                          <div className="text-small text-muted">@mrhamburger</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="text-small">I have always had a real problem with having negative thoughts constantly. But since I started listening to Bob's app, I notice that I don't immediately have a negative thought about everything. I actually can wake up in the morning and think something positive. I actually can envision a positive outcome more often.</div>
                    </div>
                  </div>
                  <div data-w-id="4783cacc-8e65-fdc8-d1e9-ff479689db09" className="card no-border">
                    <div className="card-head no-border">
                      <div className="row row-align-center"><img src="/images/5dc99dd0c367665a19593782_avatar-female-4.jpg" alt="" className="avatar avatar-small" />
                        <div className="text-space-left">
                          <div className="text-small"><strong>Very Helpful!!!</strong></div>
                          <div className="text-small text-muted">@openmind60</div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="text-small">I really recommend this app, I think most if not all of US need a positive thinking and outlook in life especially at times like these.!!!!!!!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DownloadApp />
    </div>
  </Layout>
)

export default IndexPage
