/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import Header from "./Header"
import Footer from "./Footer"

// Copied the old one from webflow
import "./the-wise-guide-app.6ba67caeb.css";
import "./styles.css";

const Layout = ({ showCart, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/*
      <Helmet>
        <!--[if lt IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js" type="text/javascript"></script><![endif]-->
        <script src="/jquery-3.4.1.min.220afd743d.js" />
        <script src="/the-wise-guide-app.eb534a43f.js" />
      </Helmet>
      */}

      <Helmet>
        <script data-cfasync="false">{`window.ju_num="5922C48E-51D7-4608-80EE-DA73788F2E80";window.asset_host='//cdn.jst.ai/';(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');`}</script>
      </Helmet>

      <div id="top" className="back-to-top-container">
        <div className="back-to-top-button-wrapper"><a href="#top" className="button-circle bg-primary-1 w-inline-block"><img src="/fonts/5dc99dd0c367668ca059371c_icon-chevron-up.svg" alt="" /></a></div>
      </div>

      <Header showCart={showCart} siteTitle={data.site.siteMetadata.title} />

      {children}

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

